<template>
  <div class="licences-table">
    <vx-card>
      <vue-bootstrap4-table :rows="data" :columns="getColumns" :config="config" :actions="actions"
                            @on-click-statistics="openStatisticsView"
                            @on-change-per-page="onChangePerPage"
                            @on-change-query="onChangeQuery" :total-rows="total_rows" columnSelection>
        <template slot="active" slot-scope="props" class="column-active">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                  <vx-tooltip text="Inaktiv">
                    <span class="mega--circle red" v-if="props.row.is_active == '0'"></span>
                  </vx-tooltip>
                  <vx-tooltip text="Aktiv">
                    <span class="mega--circle green" v-if="props.row.is_active == '1'"></span>
                  </vx-tooltip>
              </vs-row>
            </div>
          </div>
        </template>

        <!-- isLive Slot -->
        <template slot="isLive" slot-scope="props" class="column-isLive">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vx-tooltip text="Nicht live">
                  <span class="mega--circle red" v-if="props.row.isLive == '0'"></span>
                </vx-tooltip>
                <vx-tooltip text="Live">
                  <span class="mega--circle green" v-if="props.row.isLive == '1'"></span>
                </vx-tooltip>
              </vs-row>
            </div>
          </div>
        </template>

        <template slot="commission_recipient" slot-scope="props">
          <div class="vbt-table-td">
            <div v-if="props.row.commission_recipient_client">
              {{ props.row.commission_recipient_client.company_name }}
            </div>
            <div v-else>
              <span class="text-muted">Kein Provisionsempfänger</span>
            </div>

            <!-- Warnung, falls Empfänger ≠ Partner-Agentur -->
            <div v-if="props.row.commission_recipient_client_id && props.row.partner_client_id && props.row.commission_recipient_client_id !== props.row.partner_client_id">
      <span class="text-danger text-sm">
        Achtung: Die Provision geht an einen anderen Empfänger als die Partneragentur.
      </span>
            </div>
          </div>
        </template>

        <!-- marketing_approval Slot -->
        <template slot="marketing_approval" slot-scope="props" class="column-marketing-approval">
          <div class="vbt-table-td">
            <div class="pl-2">
              <vs-row>
                <vx-tooltip text="Keine Freigabe">
                  <span class="mega--circle red" v-if="props.row.marketing_approval == '0'"></span>
                </vx-tooltip>
                <vx-tooltip text="Freigegeben">
                  <span class="mega--circle green" v-if="props.row.marketing_approval == '1'"></span>
                </vx-tooltip>
              </vs-row>
            </div>
          </div>
        </template>

        <template slot="row_actions" slot-scope="props">
          <div class="column-actions-group">
            <vx-tooltip text="Bearbeiten" position="left">
              <router-link :to="'/licence-manager/'+props.row.id">
                <vs-button color="warning" type="filled" icon="edit" size="small"></vs-button>
              </router-link>
            </vx-tooltip>
            <vx-tooltip text="Deaktivieren" position="left" v-if="props.row.is_active">
              <vs-button color="danger"
                         type="filled"
                         icon="close"
                         size="small"
                         @click="toggleActive(props.row.id, props.row.is_active)"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Aktivieren" position="left" v-if="!props.row.is_active">
              <vs-button color="success"
                         type="filled"
                         icon="check"
                         size="small"
                         @click="toggleActive(props.row.id, props.row.is_active)"></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Löschen" position="left">
              <vs-button color="primary"
                         type="filled"
                         icon="delete_forever"
                         size="small"
                         @click="openDeleteConfirm(props.row.id)"></vs-button>
            </vx-tooltip>
          </div>
        </template>
        <template slot="empty-results">
          Keine Lizenzen gefunden.
        </template>
      </vue-bootstrap4-table>
    </vx-card>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";
import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import findIndex from "lodash/findIndex";
import vSelect from 'vue-select'

var qs = require('qs');

export default {
  components: {
    VueBootstrap4Table,
    'v-select': vSelect
  },
  data() {
    return {
      data: [],
      actions: [
        {
          btn_text: "Hinzufügen",
          router_link: '/licence-manager/add',
          color: 'success'
        },
        {
          btn_text: "Statistiken",
          event_name: "on-click-statistics",
          color: 'primary'
        }
      ],
      columns: [
        {
          label: "Aktiv",
          name: "is_active",
          slot_name: "active",
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'active-column',
          showCol: true,
        },
        {
          label: "Shop ist live",
          name: "isLive",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'live-column',
          showCol: true,
        },
        {
          label: "LIZENZ-ID",
          name: "id",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'id-column',
          showCol: true,
        },

        {
          label: "Lizenzplan",
          name: "license_plan",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'license-plan-column',
          showCol: true,
        },
        {
          label: "Lizenz-Inhaber (Rechnungsemp.)",
          name: "owner_client.company_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'domain-column',
          showCol: true,
        },

        {
          label: "Lizenz-Nutzer (Shopbetr.)",
          name: "usedby_client.company_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'domain-column',
          showCol: true,
        }, {
          label: "Partner (Agentur)",
          name: "partner_client.company_name",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'domain-column',
          showCol: true,
        },
        {
          label: "Partner (Agentur) Provision Note",
          name: "partner_provision_note",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'provision-column',
          showCol: false,
        },
        {
          label: "Domain",
          name: "domain",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'domain-column',
          showCol: true,
        },
        {
          label: "Test-Domain",
          name: "test_domains",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'domain-column',
          showCol: false,
        },
        {
          label: "Marketingfreigabe",
          name: "marketing_approval",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'approval-column',
          showCol: false,
        },
        {
          label: "Marketing Status",
          name: "marketing_status",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'status-column',
          showCol: false,
        },

        {
          label: "Provisionsvereinbarung",
          name: "commission_agreement_type",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          column_classes: "commission-agreement-column",
          showCol: false,
          formatter: (value) => {
            const agreementLabels = {
              "none_is_endcustomer": "Keine - Endkunde",
              "none_no_partner_agency": "Keine - Keine Partneragentur",
              "none_accounting_via_agency": "Keine - Abrechnung über Agentur",
              "none_individual_agreement": "Keine - Individuelle Vereinbarung",
              "partner_contract": "Provision nach Partnervertrag"
            };
            return agreementLabels[value] || value;
          }
        },
        {
          label: "Provision (%)",
          name: "commission_percentage",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          column_classes: "commission-percentage-column",
          showCol: false,
        },
        {
          label: "Provisionsempfänger",
          name: "commission_recipient_client.company_name",
          slot_name: "commission_recipient",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: "text-left",
          column_text_alignment: "text-left",
          column_classes: "commission-recipient-column",
          showCol: false,
        },

        {
          label: "Interner Kommentar",
          name: "internal_comment",
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'comment-column',
          showCol: false,
        },

        {
          label: "Erstellt",
          name: "created_at",
          slot_name: "created_at",
          filter: {
            type: 'date',
            mode: 'range'
          },
          sort: true,
          row_text_alignment: 'text-left',
          column_text_alignment: 'text-left',
          column_classes: 'date-column',
          showCol: false,
          formatter: this.formatDateTime
        },

        {
          label: "Aktionen",
          slot_name: "row_actions",
          sort: false,
          row_text_alignment: 'text-right',
          column_text_alignment: 'text-right',
          column_classes: 'actions-column',
          showCol: true,
        }],
      config: {
        checkbox_rows: false,
        show_refresh_button: false,
        show_reset_button: false,
        highlight_row_hover_color: '#f8f8f8',
        server_mode: true,
        per_page: 10
      },
      queryParams: {
        sort: [],
        filters: [],
        global_search: "",
        per_page: 10,
        page: 1,
      },
      total_rows: 0,
      selectedForDialog: null,
    }
  },
  computed: {
    requestParams() {
      return QueryHelper.parseRequestParams(this.queryParams);
    },
    getColumns() {
      return this.columns;
    },
  },
  methods: {
    fetchData() {
      this.$vs.loading();

      let licencesPromise = ApiService.get('b2b-sellers/licence', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });

      licencesPromise.then((response) => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
        this.$vs.loading.close();
      });
    },
    refreshLicences() {
      ApiService.get('b2b-sellers/licence', {
        params: this.requestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then((response) => {
        this.data = response.data.result;
        this.total_rows = response.data.total;
      });
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY - H:m')
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    toggleActive(id, current) {
      const payload = {
        is_active: !current
      };

      ApiService.put('b2b-sellers/licence/' + id, payload).then(response => {
        this.fetchData();

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Der Status wurde erfolgreich geändert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Der Status konnte nicht geändert werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
    openDeleteConfirm(id) {
      this.selectedForDialog = id;

      this.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: 'Bestätigen',
        text: 'Sind Sie sich sicher dass diese Lizenz gelöscht werden soll?',
        accept: this.acceptDelete,
        acceptText: 'Löschen',
        cancelText: 'Abbrechen'
      })
    },
    openStatisticsView() {
      console.log('open statistics view');


      this.$router.push({ name: 'licence-manager-statistics' });
    },
    acceptDelete() {
      ApiService.delete('b2b-sellers/licence/' + this.selectedForDialog).then((response) => {
        this.fetchData();

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Lizenz wurde erfolgreich gelöscht',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Lizenz konnte nicht gelöscht werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    },
  },
  mounted() {
    this.fetchData();
  }
}
</script>

<style lang="scss">
.licences-table {
  .id-column {
    width: 10%;
  }
  .domain-column {
    width: 20%;
  }
  .license-plan-column {
    width: 15%;
  }
  .date-column {
    width: 15%;
  }
  .actions-column {
    width: 15%;
  }
  .active-column {
    width: 10%;
  }
}
</style>
